<template>
  <div class="choose-loan-term"
       v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.PRE_ORIGINATION_ENTER_LEAVE_CHOOSE_LOAN_TERM.value, path: $route.path}">
    <div class="main-content">
      <div v-min-height="300">
        <loan-term-group
          :terms="layouts.data.termsArray"
          :checkIndex="layouts.data.termIndex"
          @onClickTerm="onClickTerm"
        ></loan-term-group>
      </div>
      <div class="preview-panel">
        <a
          @click="previewPaymentPlan"
        >Preview Payment Plan</a>
      </div>
    </div>
    <div class="footer-wrap">
      <wap-btn
        :disabled-control="!layouts.data.loanDetail.period"
        :theme="!layouts.data.loanDetail.period ? '' : 'primary'"
        :loading="layouts.data.loader"
        :block="true"
        width="100%"
        @click="onClickNext"
      >Continue
      </wap-btn>
    </div>
    <preview-payment-schedule
      v-model="layouts.data.showPreview"
      :paymentInstallment="layouts.data.loanDetail.previewInstallmentList"
      height="50"
      title="Payment Plan"
      @confirm="layouts.data.showPreview = false"
    >
    </preview-payment-schedule>
  </div>
</template>

<script>
import { Tool, Currency, DataType } from '@/assets/js/util'
import { OnlineApi } from '@/api'
import LoanTermGroup from '@/components/disbursement/LoanTermGroup'
import PreviewPaymentSchedule from '@/components/disbursement/PreviewPaymentSchedule'
import WapBtn from '@/components/base/WapBtn'

const currency = new Currency()
const tool = new Tool()
export default {
  name: 'ChooseTerms',
  components: {
    LoanTermGroup,
    PreviewPaymentSchedule,
    WapBtn
  },
  data: () => ({
    assist: {
      tool: tool,
      currency: currency,
      dataType: DataType
    },
    layouts: {
      settings: {},
      data: {
        loader: false,
        termIndex: null,
        termsArray: [
          {
            loanMonth: 12,
            ofPayments: '26',
            regularAmount: '134.57',
            firstDueDate: '04/07/2023',
            maturityDate: '03/22/2024',
            APR: '7.7784%'
          }
        ],
        showPreview: false,
        loanDetail: {
          period: null,
          approvedAmount: null,
          previewInstallmentList: null
        }
      }
    }
  }),
  watch: {},
  mounted () {
    this.calculatePaymentSchedule(12, true)
  },
  methods: {
    calculatePaymentSchedule (period, isPreview) {
      const request = {
        terms: period,
        isPreview: isPreview
      }
      OnlineApi.calculatePaymentSchedule(request, (result) => {
        if (isPreview) {
          this.layouts.data.loanDetail.previewInstallmentList = result.installments
          this.layouts.data.termsArray = []
          this.layouts.data.termsArray.push({
            loanMonth: period,
            ofPayments: result.installments.length - 1,
            regularAmount: result.regularAmount,
            firstDueDate: result.firstDebitDate,
            maturityDate: result.lastDebitDate,
            APR: result.apr
          })
          this.layouts.data.loanDetail.period = period
          this.layouts.data.termIndex = this.layouts.data.termsArray.length - 1
        } else {
          this.$router.push('/disbursement/confirm-details')
        }
      })
    },
    onClickTerm (item) {
      this.layouts.data.loanDetail.period = item.loanMonth
      item.installmentList = [
        {
          amount: 145.56,
          bankFee: 0.00,
          changeType: 'Normal',
          flags: 0,
          id: 423130067,
          interest: 145.56,
          lateFee: 0.00,
          nsfFee: 0.00,
          principal: 0.00,
          transactionAmount: 145.56,
          transactionDate: '07/14/2023',
          transactionMode: 'ACH',
          transactionStatus: 'None',
          transactionType: 'Debit',
          unpaidFee: 0.00
        },
        {
          amount: 145.56,
          bankFee: 0.00,
          changeType: 'Normal',
          flags: 0,
          id: 423130068,
          interest: 142.10,
          lateFee: 0.00,
          nsfFee: 0.00,
          principal: 0.00,
          transactionAmount: 145.56,
          transactionDate: '07/28/2023',
          transactionMode: 'ACH',
          transactionStatus: 'None',
          transactionType: 'Debit',
          unpaidFee: 3.46
        },
        {
          amount: 145.56,
          bankFee: 0.00,
          changeType: 'Normal',
          flags: 0,
          id: 423130069,
          interest: 142.10,
          lateFee: 0.00,
          nsfFee: 0.00,
          principal: 0.00,
          transactionAmount: 145.56,
          transactionDate: '08/11/2023',
          transactionMode: 'ACH',
          transactionStatus: 'None',
          transactionType: 'Debit',
          unpaidFee: 3.46
        },
        {
          amount: 145.56,
          bankFee: 0.00,
          changeType: 'Normal',
          flags: 0,
          id: 423130070,
          interest: 142.10,
          lateFee: 0.00,
          nsfFee: 0.00,
          principal: 0.00,
          transactionAmount: 145.56,
          transactionDate: '08/25/2023',
          transactionMode: 'ACH',
          transactionStatus: 'None',
          transactionType: 'Debit',
          unpaidFee: 3.46
        },
        {
          amount: 145.56,
          bankFee: 0.00,
          changeType: 'Normal',
          flags: 0,
          id: 423130071,
          interest: 142.10,
          lateFee: 0.00,
          nsfFee: 0.00,
          principal: 0.00,
          transactionAmount: 145.56,
          transactionDate: '09/08/2023',
          transactionMode: 'ACH',
          transactionStatus: 'None',
          transactionType: 'Debit',
          unpaidFee: 3.46
        },
        {
          amount: 145.56,
          bankFee: 0.00,
          changeType: 'Normal',
          flags: 0,
          id: 423130072,
          interest: 142.10,
          lateFee: 0.00,
          nsfFee: 0.00,
          principal: 0.00,
          transactionAmount: 145.56,
          transactionDate: '09/22/2023',
          transactionMode: 'ACH',
          transactionStatus: 'None',
          transactionType: 'Debit',
          unpaidFee: 3.46
        },
        {
          amount: 145.56,
          bankFee: 0.00,
          changeType: 'Normal',
          flags: 0,
          id: 423130073,
          interest: 142.10,
          lateFee: 0.00,
          nsfFee: 0.00,
          principal: 0.00,
          transactionAmount: 145.56,
          transactionDate: '10/06/2023',
          transactionMode: 'ACH',
          transactionStatus: 'None',
          transactionType: 'Debit',
          unpaidFee: 3.46
        },
        {
          amount: 145.56,
          bankFee: 0.00,
          changeType: 'Normal',
          flags: 0,
          id: 423130074,
          interest: 142.10,
          lateFee: 0.00,
          nsfFee: 0.00,
          principal: 0.00,
          transactionAmount: 145.56,
          transactionDate: '10/20/2023',
          transactionMode: 'ACH',
          transactionStatus: 'None',
          transactionType: 'Debit',
          unpaidFee: 3.46
        },
        {
          amount: 145.56,
          bankFee: 0.00,
          changeType: 'Normal',
          flags: 0,
          id: 423130075,
          interest: 142.10,
          lateFee: 0.00,
          nsfFee: 0.00,
          principal: 0.00,
          transactionAmount: 145.56,
          transactionDate: '11/03/2023',
          transactionMode: 'ACH',
          transactionStatus: 'None',
          transactionType: 'Debit',
          unpaidFee: 3.46
        },
        {
          amount: 145.56,
          bankFee: 0.00,
          changeType: 'Normal',
          flags: 0,
          id: 423130076,
          interest: 142.10,
          lateFee: 0.00,
          nsfFee: 0.00,
          principal: 0.00,
          transactionAmount: 145.56,
          transactionDate: '11/17/2023',
          transactionMode: 'ACH',
          transactionStatus: 'None',
          transactionType: 'Debit',
          unpaidFee: 3.46
        },
        {
          amount: 145.56,
          bankFee: 0.00,
          changeType: 'Normal',
          flags: 0,
          id: 423130077,
          interest: 142.10,
          lateFee: 0.00,
          nsfFee: 0.00,
          principal: 0.00,
          transactionAmount: 145.56,
          transactionDate: '12/01/2023',
          transactionMode: 'ACH',
          transactionStatus: 'None',
          transactionType: 'Debit',
          unpaidFee: 3.46
        },
        {
          amount: 145.56,
          bankFee: 0.00,
          changeType: 'Normal',
          flags: 0,
          id: 423130078,
          interest: 142.10,
          lateFee: 0.00,
          nsfFee: 0.00,
          principal: 0.91,
          transactionAmount: 145.56,
          transactionDate: '12/15/2023',
          transactionMode: 'ACH',
          transactionStatus: 'None',
          transactionType: 'Debit',
          unpaidFee: 2.55
        }]
      this.layouts.data.loanDetail.previewInstallmentList = item.installmentList
      this.$store.commit('setChooseTerms', {
        period: item.loanMonth,
        uuid: this.$store.getters.getLoan.uuid,
        previewInstallmentList: item.installmentList
      })
    },
    previewPaymentPlan () {
      if (this.layouts.data.loanDetail.period) {
        this.layouts.data.showPreview = true
      } else {
        this.$store.commit('setPopupInformation', {
          message: 'Please choose a loan term to continue.'
        })
      }
    },
    onClickNext () {
      this.calculatePaymentSchedule(12, false)
    }
  }
}
</script>

<style lang="sass" scoped>
.choose-loan-term
  & .preview-panel
    padding: 32px 16px
    text-align: center

    & a
      color: var(--theme_primary)
</style>
